import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDispatch } from 'react-redux';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import RegistrationSecurityQuestionsForm, {
    SecurityQuestionsFormValues
} from 'components/registration-security-questions/registration-security-questions';
import { FormikHelpers } from 'formik';
import { accountRegisterSecurityQuestionsRoutine } from 'state/account/account.routines';

const ProfileSecurityQuestions = (): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleSubmit = (
        values: Partial<SecurityQuestionsFormValues>,
        helpers: FormikHelpers<Partial<SecurityQuestionsFormValues>>
    ) => {
        dispatch(
            accountRegisterSecurityQuestionsRoutine.trigger({
                formValues: values,
                formHelpers: helpers
            })
        );
        navigate('/get-started/profile-setup');
    };

    return (
        <WorkflowLayout backgroundImage={imageData.blueSkyBackground} useRoundedCorners={false}>
            <WorkflowLayoutFormWrapper eyebrowText={t('profile.eyebrowText')} title={t('registration.securityTitle')}>
                <RegistrationSecurityQuestionsForm onSubmit={handleSubmit} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ProfileSecurityQuestions;
