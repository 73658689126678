import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { REGISTRATION_SECURITY_QUESTION_ANSWER_SCHEMA } from 'schema/registration.schema';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import WorkflowSteps from 'ui-kit/workflow-steps/workflow-steps';

import FormSelect from 'ui-kit/form-select/form-select';
import axiosClient from 'util/axiosClient';
import CogIcon from 'ui-kit/icons/cog-icon/cog-icon';
import { SelectChangeEvent } from 'ui-kit/select/select.props';

export interface SecurityQuestionsFormValues {
    question1: string;
    question2: string;
    question3: string;
    answer1: string;
    answer2: string;
    answer3: string;
}

export interface RegistrationSecurityQuestionsFromProps {
    className?: string;
    onSubmit: (
        values: Partial<SecurityQuestionsFormValues>,
        helpers: FormikHelpers<Partial<SecurityQuestionsFormValues>>
    ) => void;
}

const RegistrationSecurityQuestionsForm = ({ className, onSubmit }: RegistrationSecurityQuestionsFromProps) => {
    const classes = classNames(className);
    const { t } = useTranslation();
    const [originalSecurityQuestions, setOriginalSecurityQuestions] = useState([]);

    const [selectedSecuirtyQuestion1, setSelectedSecuirtyQuestion1] = useState<string | undefined>(undefined);
    const [securityQuestions1, setSecurityQuestions1] = useState([]);

    const [selectedSecuirtyQuestion2, setSelectedSecuirtyQuestion2] = useState<string | undefined>(undefined);
    const [securityQuestions2, setSecurityQuestions2] = useState([]);

    const [selectedSecuirtyQuestion3, setSelectedSecuirtyQuestion3] = useState<string | undefined>(undefined);
    const [securityQuestions3, setSecurityQuestions3] = useState([]);

    const [isLoadingQuestions, setIsLoadingQuesitons] = useState(true);
    const formName = 'RegistrationForm';

    const handleSelectedSecurityQuestionsChange = ({ e, index }: { e: SelectChangeEvent<any>; index: number }) => {
        let nextSecurityQuestions = [...originalSecurityQuestions].filter(({ key }: { key: string }) => key !== e.key);

        switch (index) {
            case 1: {
                setSelectedSecuirtyQuestion1(e.key);

                setSecurityQuestions2(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion3)
                );
                setSecurityQuestions3(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion2)
                );
                break;
            }
            case 2: {
                setSelectedSecuirtyQuestion2(e.key);

                setSecurityQuestions1(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion3)
                );
                setSecurityQuestions3(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion1)
                );
                break;
            }
            case 3: {
                setSelectedSecuirtyQuestion3(e.key);

                setSecurityQuestions1(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion2)
                );
                setSecurityQuestions2(
                    nextSecurityQuestions.filter(({ key }: { key: string }) => key !== selectedSecuirtyQuestion1)
                );
                break;
            }
        }
    };
    const getSecurityQuestionsByIndex = ({ index }: { index: number }) => {
        switch (index) {
            case 1: {
                return securityQuestions1;
            }
            case 2: {
                return securityQuestions2;
            }
            case 3: {
                return securityQuestions3;
            }
        }
    };

    useEffect(() => {
        axiosClient.get(`${process.env.GATSBY_API_BASE_URL}/api/Values/SecurityQuestions`).then(({ data }) => {
            const questions = Object.values(data)
                .slice(1)
                .map((question) => ({
                    key: question,
                    label: question,
                    value: question
                }));

            setOriginalSecurityQuestions([...questions]);
            setSecurityQuestions1([...questions]);
            setSecurityQuestions2([...questions]);
            setSecurityQuestions3([...questions]);

            setIsLoadingQuesitons(false);
        });
    }, []);

    return (
        <div className={classes}>
            {isLoadingQuestions ? (
                <CogIcon className="btn-cog-icon" style={{ height: '100%', width: '2rem' }} />
            ) : (
                <Row>
                    <Col>
                        <Formik
                            onSubmit={onSubmit}
                            validationSchema={REGISTRATION_SECURITY_QUESTION_ANSWER_SCHEMA}
                            initialValues={{
                                question1: undefined,
                                question2: undefined,
                                question3: undefined,
                                answer1: undefined,
                                answer2: undefined,
                                answer3: undefined
                            }}
                        >
                            {({ values, touched, errors, handleChange, handleSubmit }) => (
                                <Form
                                    id="question-form"
                                    data-ga-form-name={formName}
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                >
                                    {[1, 2, 3].map((index) => {
                                        return (
                                            <div key={index}>
                                                <Row>
                                                    <Col className="d-flex flex-column align-items-center">
                                                        <Field
                                                            id={`question${index}`}
                                                            name={`question${index}`}
                                                            onSelectionChanged={(e: SelectChangeEvent<any>) =>
                                                                handleSelectedSecurityQuestionsChange({ e, index })
                                                            }
                                                            options={getSecurityQuestionsByIndex({ index })}
                                                            component={FormSelect}
                                                            value={values[`question${index}`]}
                                                            placeholder={`Security Question ${index}`}
                                                            errors={
                                                                errors[`question${index}`]
                                                                    ? t('forms.errorMessages.requiredField', {
                                                                          label: t('pages.profile.addAddress.state')
                                                                      })
                                                                    : undefined
                                                            }
                                                            touched={touched[`question-${index}`]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="d-flex flex-column">
                                                        <Text
                                                            name={`answer${index}`}
                                                            label={`Security Question Answer ${index}`}
                                                            onChange={handleChange}
                                                            errors={
                                                                errors[`answer${index}`]
                                                                    ? t(`profile.errors.lastName`)
                                                                    : undefined
                                                            }
                                                            touched={touched[`answer${index}`]}
                                                            value={values[`answer${index}`]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    })}
                                    <Row>
                                        <Col className="d-flex flex-column align-items-center">
                                            <Button
                                                label={t('registration.submit')}
                                                variant="primary"
                                                type="submit"
                                                dataGAFormName={formName}
                                                dataGAFormStep="Step2"
                                                dataGAFormStepName="SecurityQuestions"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        <Row>
                            <Col className="d-flex flex-column align-items-center mt-3">
                                <WorkflowSteps
                                    id="registration-page"
                                    activeStepName="Questions"
                                    activeStep={2}
                                    numberOfSteps={3}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default RegistrationSecurityQuestionsForm;
